// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/_layout/_common/_document/index.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/_layout/_common/_document/index.tsx");
  import.meta.hot.lastModified = "1738902500000";
}
// REMIX HMR END

import { Links, LiveReload, Meta, Scripts, ScrollRestoration, useLoaderData } from '@remix-run/react';
import { useTranslation } from 'react-i18next';
import { useChangeLanguage } from 'remix-i18next/react';
import { Toaster } from 'react-hot-toast';
import SeoMetaTags from '../_includes/Meta';
import { useEffect } from 'react';
import { AppStoreProvider as SliderProvider } from '../../../Slider/_provider/SliderContext';
export default function Document({
  children
}) {
  _s();
  const {
    locale
  } = useLoaderData();
  useEffect(() => {
    console.log({
      locale
    });
  }, [locale]);
  let {
    i18n
  } = useTranslation();

  // This hook will change the i18n instance language to the current locale
  // detected by the loader, this way, when we do something to change the
  // language, this locale will change and i18next will load the correct
  // translation files
  useChangeLanguage(locale);
  return <html lang={locale} dir={i18n.dir()}>
      <head>
        <meta charSet='utf-8' />
        <meta name='viewport' content='width=device-width,initial-scale=1' />
        <SeoMetaTags />
        <Meta />
        <Links />
      </head>
      <body>
        <SliderProvider value={{
        isOpen: false
      }}>{children}</SliderProvider>
        <Toaster />
        <ScrollRestoration />
        <Scripts />
        <LiveReload />
      </body>
    </html>;
}
_s(Document, "XNMgqOMRrtqdjx4pRg4o8HGNKNw=", false, function () {
  return [useLoaderData, useTranslation, useChangeLanguage];
});
_c = Document;
var _c;
$RefreshReg$(_c, "Document");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;